<template>
<div v-loading="loading" style="width: 100%; height: 700px; background: #FFFFFF; padding: 30px">
  <div>
    <el-select v-model="selType" placeholder="请选择">
      <el-option
        v-for="(item, index) in typeArray"
        :key="index"
        :label="item"
        :value="index">
      </el-option>
    </el-select>
    <el-button @click="uploadDialog = true" type="primary" style="float: right"><i class="el-icon-upload el-icon--right">  上传报告</i></el-button>
  </div>
  <div style="margin-top: 30px">
    <el-table
      :data="wisdomArray[selType]"
      border
      style="width: 100%">
      <el-table-column
        label="封面"
        align="center"
        width="180">
        <template scope="{ row }">
          <el-image
            style="width: 150px; height: 100px"
            :src="row.cover"
            fit="cover"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="title"
        label="标题"
        width="180">
      </el-table-column>
      <el-table-column
        prop="summary"
        label="摘要"
        >
      </el-table-column>
      <el-table-column
        prop="type"
        label="类型"
        width="100">
      </el-table-column>
      <el-table-column
        label="操作"
        :show-overflow-tooltip="true"
        width="180">
        <template scope="{ row }">
          <el-button @click="showInfo(row.url)" size="mini" type="success" icon="el-icon-view"></el-button>
          <el-button @click="showEditDialog(row)" size="mini" type="primary" icon="el-icon-edit-outline"></el-button>
          <el-button @click="delId = row.id, delDialog = true" size="mini" type="danger" icon="el-icon-delete"></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <el-dialog
    title="上传报告"
    :visible.sync="uploadDialog"
    width="40%">
    <div v-loading="addLoading">
      <div>
        <el-input v-model="form.title" placeholder="请输入报告标题"></el-input>
      </div>
      <div style="margin-top: 20px">
        <el-input
          placeholder="请输入报告所属行业"
          v-model="form.type">
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入报告摘要"
          v-model="form.summary">
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <el-upload
          accept="image/*"
          class="avatar-uploader"
          :action="apiBaseURL + '/wisdomLib/upload'"
          :show-file-list="false"
          :headers="headers"
          :on-success="handelCoverSuccess"
          :before-remove="beforeRemove">
          <el-image style="width: 100%" fit="cover" v-if="form.cover" :src="form.cover" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip" class="el-upload__tip">点击上传报告封面</div>
        </el-upload>
      </div>
      <div style="margin-top: 20px">
        <el-upload
          accept="application/vnd.ms-powerpoint, application/pdf"
          class="upload-demo"
          :action="apiBaseURL + '/wisdomLib/upload'"
          :headers="headers"
          :on-success="handelSuccess"
          :before-remove="beforeRemove">
          <el-button size="small" type="primary">点击上传报告文件</el-button>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="uploadDialog = false">取 消</el-button>
    <el-button type="primary" @click="add">确 定</el-button>
  </span>
  </el-dialog>
  <el-dialog
    title="编辑报告"
    :visible.sync="editdDialog"
    width="40%">
    <div v-loading="editLoading">
      <div>
        <el-input v-model="editForm.title" placeholder="请输入报告标题"></el-input>
      </div>
      <div style="margin-top: 20px">
        <el-input
          placeholder="请输入报告所属行业"
          v-model="editForm.type">
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <el-input
          type="textarea"
          :rows="3"
          placeholder="请输入报告摘要"
          v-model="editForm.summary">
        </el-input>
      </div>
      <div style="margin-top: 20px">
        <el-upload
          accept="image/*"
          class="avatar-uploader"
          :action="apiBaseURL + '/wisdomLib/upload'"
          :show-file-list="false"
          :headers="headers"
          :on-success="handelEditCoverSuccess"
          :before-remove="beforeRemove">
          <el-image style="width: 100%" fit="cover" v-if="editForm.cover" :src="editForm.cover" class="avatar" />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          <div slot="tip" class="el-upload__tip">点击修改报告封面</div>
        </el-upload>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="editdDialog = false">取 消</el-button>
    <el-button type="primary" @click="confirmUpdate">确 定</el-button>
  </span>
  </el-dialog>
  <el-dialog
    title="删除确认"
    :visible.sync="delDialog"
    width="30%">
    <div v-loading="delLoading">
      <span>确定要删除该报告吗？</span>
    </div>
    <span slot="footer" class="dialog-footer">
    <el-button @click="delDialog = false">取 消</el-button>
    <el-button type="primary" @click="confirmDel">确 定</el-button>
  </span>
  </el-dialog>
</div>
</template>

<script>
import { listWisdom, addWisdom, removeWisdom, updateWisdom } from '@/api/wisdomLib'
import { getToken } from '@/utils/auth'
import { apiBaseURL } from '@/utils/request'
export default {
  data () {
    return {
      loading: true,
      typeArray: [],
      wisdomArray: [],
      selType: 0,
      uploadDialog: false,
      editdDialog: false,
      delDialog: false,
      headers: {
        token: getToken()
      },
      apiBaseURL: apiBaseURL,
      form: {
        url: '',
        cover: '',
        title: '',
        summary: '',
        type: ''
      },
      editForm: {},
      delId: '',
      addLoading: false,
      editLoading: false,
      delLoading: false
    }
  },
  mounted () {
    this.fetchList()
  },
  methods: {
    showInfo (url) {
      window.open(url + '&token=' + getToken(), '_blank')
    },
    async fetchList () {
      this.loading = true
      const res = await listWisdom({ keyword: '' })
      var _this = this
      _this.typeArray = []
      _this.wisdomArray = []
      Object.keys(res.data).forEach(function (key) {
        _this.typeArray.push(key)
        _this.wisdomArray.push(res.data[key])
      })
      this.loading = false
    },
    beforeRemove (file) {
      return this.$confirm(`确定移除 ${file.name}？`)
    },
    handelSuccess (response, file, fileList) {
      this.form.url = response.msg
    },
    handelCoverSuccess (response, file, fileList) {
      this.form.cover = response.msg
    },
    handelEditCoverSuccess (response, file, fileList) {
      this.editForm.cover = response.msg
    },
    async add () {
      this.addLoading = true
      if (this.form.title && this.form.summary && this.form.type && this.form.url && this.form.cover) {
        const res = await addWisdom(this.form)
        this.addLoading = false
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.uploadDialog = false
          this.form = {
            url: '',
            cover: '',
            title: '',
            summary: '',
            type: ''
          }
          this.fetchList()
        } else {
          this.$message.error(res.msg)
        }
      } else {
        this.$message.warning('表单中的所有内容必须填写或上传')
      }
    },
    async confirmUpdate () {
      this.editLoading = true
      if (this.editForm.title && this.editForm.summary && this.editForm.type && this.editForm.cover) {
        const res = await updateWisdom(this.editForm)
        this.editLoading = false
        if (res.code === 0) {
          this.$message.success(res.msg)
          this.editdDialog = false
          this.fetchList()
        } else {
          this.$message.error(res.msg)
        }
      } else {
        this.$message.warning('表单中的所有内容必须填写或上传')
      }
    },
    async confirmDel () {
      this.delLoading = true
      const res = await removeWisdom({ id: this.delId })
      this.delLoading = false
      if (res.code === 0) {
        this.$message.success(res.msg)
        this.delDialog = false
        this.fetchList()
      } else {
        this.$message.error(res.msg)
      }
    },
    showEditDialog (item) {
      this.editForm = JSON.parse(JSON.stringify(item))
      this.editdDialog = true
    }
  }
}
</script>

<style>
.avatar-uploader .el-upload {
  width: 100%;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 230px;
  height: 230px;
  line-height: 230px;
  text-align: center;
}
.avatar {
  width: 100%;
  height: 230px;
  display: block;
}
</style>
